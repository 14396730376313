/** @jsx jsx */
import { jsx, Themed } from "theme-ui"
import PropTypes from "prop-types"
import Link from "src/components/Link"

import Section from "src/components/Section"
import SectionNarrow from "src/components/SectionNarrow"
import SectionVertical from "src/components/SectionVertical"

import Container from "src/components/Container"
import ColumnsNarrow from "src/components/ColumnsNarrow"
import ColumnCardsNarrowQuarter from "src/components/ColumnCardsNarrowQuarter"

const RelatedResource = (props) => {
  const Heading = props.headingLevel || "h3"
  return (
    <Link
      to={props.link}
      sx={{
        variant: "cards.yellow",
        display: "flex",
        width: "100%",
        flexDirection: "column",
        justifyContent: "space-between",
        borderRadius: 8,
        textDecoration: "none",
        minHeight: [124, 124, 190],
        height: "100%",
        p: [3, null, 5, null, null, 6],
      }}
    >
      <Heading
        sx={{
          variant: "links.h4",
        }}
        dangerouslySetInnerHTML={{
          __html: props.title,
        }}
      ></Heading>
    </Link>
  )
}

const RelatedResources = (props) => {
  return (
    <SectionVertical as="section" sx={{ variant: "section.large" }}>
      <Section>
        <Container>
          {props.sectionHeading && (
            <Themed.h2 id={props.sectionHeadingId}>
              {props.sectionHeading}
            </Themed.h2>
          )}
        </Container>
      </Section>

      <SectionNarrow>
        <Container>
          <ColumnsNarrow>
            {props.resources.map((resource, index) => (
              <ColumnCardsNarrowQuarter
                as="article"
                key={index}
                sx={{
                  width: ["50%", null, "50%", null, "25%"],
                  display: "flex",
                }}
              >
                <RelatedResource
                  {...resource}
                  headingLevel={props.sectionHeading ? "h3" : "h2"}
                />
              </ColumnCardsNarrowQuarter>
            ))}
          </ColumnsNarrow>
        </Container>
      </SectionNarrow>
    </SectionVertical>
  )
}

RelatedResources.propTypes = {
  sectionHeading: PropTypes.string,
  resources: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string.isRequired,
      title: PropTypes.string,
      headingLevel: PropTypes.string,
    })
  ),
}

RelatedResources.defaultProps = {
  themeColor: "Yellow",
}

export default RelatedResources

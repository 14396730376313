/** @jsx jsx */
import { jsx, Themed } from "theme-ui"
import PropTypes from "prop-types"

import Link from "src/components/Link"
import Section from "src/components/Section"
import Container from "src/components/Container"
import Columns from "src/components/Columns"
import Column from "src/components/Column"
import VisuallyHidden from "src/components/VisuallyHidden"

const BoldStatement = (props) => {
  const StatementEl = props.sectionHeading ? "p" : "Themed.h2"
  return (
    <Section as="section" sx={{ variant: "section.large" }}>
      <Container>
        <Columns>
          <Column
            sx={{
              width: [
                false,
                false,
                false,
                "91.66666667%",
                false,
                "83.33333333%",
              ],
            }}
          >
            {props.sectionHeading && (
              <h2
                id={props.sectionHeadingId}
                sx={{ variant: "text.sectionHeading" }}
              >
                {props.sectionHeading}
              </h2>
            )}
            <StatementEl
              id={!props.sectionHeading ? props.sectionHeadingId : undefined}
              sx={{
                variant: "styles.h1",
                mb: "0.75em",
                "&:first-of-type": {
                  mt: 0,
                },
                "&:last-child": {
                  mb: 0,
                },
              }}
            >
              {props.statement}
            </StatementEl>
            {props.link && (
              <Link to={props.link.url} sx={{ variant: "styles.h3", mb: 0 }}>
                Read More
                <VisuallyHidden>
                  {" "}
                  about {props.sectionHeading || props.statement}
                </VisuallyHidden>
              </Link>
            )}
          </Column>
        </Columns>
      </Container>
    </Section>
  )
}

BoldStatement.propTypes = {
  sectionHeading: PropTypes.string.isRequired,
  statement: PropTypes.string.isRequired,
  // readMoreLink: PropTypes.string.isRequired,
  link: PropTypes.shape({
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }),
}

export default BoldStatement

import React from "react"

const Increase = () => (
  <svg
    width="16"
    height="20"
    viewBox="0 0 20 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 22V2M10 2L2 10M10 2L18 10"
      stroke="black"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default Increase

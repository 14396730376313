/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"

import HeroStandard from "src/components/HeroStandard"
import HeroWithImageRight from "src/components/HeroWithImageRight"
import HeroWithFullBackground from "src/components/HeroWithFullBackground"

function HeroRenderer({ title, acfPageHeader, breadcrumbs, themeColor, acfLanguage }) {
  const { rtl: isRTL } = acfLanguage;
  switch (acfPageHeader.layout) {
    case "standard":
      return (
        <HeroStandard
          title={acfPageHeader.title ? acfPageHeader.title : title}
          introduction={acfPageHeader.introduction}
          breadcrumbs={breadcrumbs}
          bgColor={themeColor}
          buttonLink={acfPageHeader.buttonLink}
          buttonColour={acfPageHeader.buttonColour}
          isRTL={isRTL}
        />
      )

    case "image_right":
      return (
        <HeroWithImageRight
          title={acfPageHeader.title ? acfPageHeader.title : title}
          introduction={acfPageHeader.introduction}
          image={acfPageHeader.image}
          breadcrumbs={breadcrumbs}
          buttonLink={acfPageHeader.buttonLink}
          buttonColour={acfPageHeader.buttonColour}
          bgColor={themeColor}
          isRTL={isRTL}
        />
      )

    case "image_background":
      return (
        <HeroWithFullBackground
          title={acfPageHeader.title ? acfPageHeader.title : title}
          image={acfPageHeader.image}
          buttonLink={acfPageHeader.buttonLink}
          isRTL={isRTL}
        />
      )

    default:
      return null
  }
}

HeroRenderer.propTypes = {
  title: PropTypes.string.isRequired,
  acfLanguage: PropTypes.shape({
    rtl: PropTypes.oneOf(['header', 'main'])
  }).isRequired,
}

HeroRenderer.defaultProps = {
  acfLanguage: {}
}

export default HeroRenderer

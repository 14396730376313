import React from "react"

const Decrease = () => (
  <svg
    width="16"
    height="20"
    viewBox="0 0 20 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 2V22M10 22L18 14M10 22L2 14"
      stroke="black"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default Decrease

/** @jsx jsx */
import { jsx, Themed } from "theme-ui"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Link from "src/components/Link"
import PropTypes from "prop-types"

const BannerWithImage = (props) => {
  const gatsbyImageData = getImage(props?.image?.sourceLocal)
  return (
    props.link && (
      <Link
        to={props.link.url}
        sx={{
          display: ["block", null, null, "flex"],
          borderRadius: "8px",
          overflow: "hidden",
          textDecoration: "none",
          variant: `cards.${props.themeColor.toLowerCase()}`,
        }}
      >
        <figure
          sx={{
            width: [null, null, null, "50%"],
          }}
        >
          {gatsbyImageData && (
            <GatsbyImage
              image={gatsbyImageData}
              alt={props.image.altText ?? ""}
              objectFit="cover"
              objectPosition="50% 50%"
              style={{
                height: "100%",
              }}
            />
          )}
        </figure>
        <div
          sx={{
            display: "flex",
            alignItems: "center",
            width: [null, null, null, "50%"],
          }}
        >
          <div
            sx={{
              py: [5, null, 6, null, 7],
              px: [3, null, 6, null, 7],
            }}
          >
            <div
              sx={{
                p: [null, null, null, 1],
              }}
            >
              {props.title && (
                <Themed.h2
                  sx={{
                    mb: 3,
                    textDecoration: "underline",
                  }}
                >
                  <span>{props.title}</span>
                </Themed.h2>
              )}
              {props.introduction && (
                <Themed.p sx={{ mb: 0 }}>{props.introduction}</Themed.p>
              )}
            </div>
          </div>
        </div>
      </Link>
    )
  )
}

BannerWithImage.propTypes = {
  title: PropTypes.string.isRequired,
  introduction: PropTypes.string.isRequired,
  themeColor: PropTypes.string.isRequired,
  // link: PropTypes.string.isRequired,
  link: PropTypes.shape({
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }),
  image: PropTypes.shape({
    sourceLocal: PropTypes.shape({
      childImageSharp: PropTypes.object.isRequired,
    }).isRequired,
  }).isRequired,
}

BannerWithImage.defaultProps = {
  themeColor: "yellow",
}

export default BannerWithImage

import React from "react"

const Comparison = () => (
  <svg
    width="28"
    height="16"
    viewBox="0 0 32 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M30 10L2 10M30 10L22 18M30 10C30 10 24.8333 4.66667 22 2M2 10L10 2M2 10L10 18"
      stroke="black"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default Comparison

/** @jsx jsx */
import { jsx, Themed } from "theme-ui"
import Link from "src/components/Link"
import PropTypes from "prop-types"

const BannerContentSingle = (props) => {
  return (
    props.link && (
      <Link
        to={props.link.url}
        sx={{
          display: "block",
          textDecoration: "none",
          py: [5, null, 6, null, 7],
          px: [3, null, 6, null, 7],
          bg: `${props.themeColor.toLowerCase()}`,
          borderRadius: "8px",
          height: "100%",
          variant: `cards.${props.themeColor.toLowerCase()}`,
        }}
      >
        <div>
          {props.title && (
            <Themed.h2
              sx={{
                mb: 3,
                textDecoration: "underline",
              }}
            >
              {props.title}
            </Themed.h2>
          )}
          {props.introduction && (
            <Themed.p sx={{ mb: 0 }}>{props.introduction}</Themed.p>
          )}
        </div>
      </Link>
    )
  )
}

BannerContentSingle.propTypes = {
  title: PropTypes.string.isRequired,
  introduction: PropTypes.string.isRequired,
  themeColor: PropTypes.string.isRequired,
  link: PropTypes.shape({
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }),
}

BannerContentSingle.defaultProps = {
  themeColor: "yellow",
}

export default BannerContentSingle

/** @jsx jsx */
import { jsx, Themed } from "theme-ui"
import PropTypes from "prop-types"
import { pxToRem } from "src/helpers"

import Section from "src/components/Section"
import Container from "src/components/Container"
import Columns from "src/components/Columns"
import Column from "src/components/Column"
import SvgDownload from "src/assets/svg/download.inline.svg"

const Download = (props) => {
  return (
    props.sourceLocal &&
    props.sourceLocal.publicURL && (
      <a
        href={props.sourceLocal.publicURL}
        sx={{
          variant: "link.text",
          display: "flex",
          alignItems: "center",
          color: "black",
          textDecoration: "none",
          lineHeight: 1,
        }}
      >
        <span style={{ width: 15, height: 14 }} aria-hidden="true">
          <SvgDownload />
        </span>
        <span
          sx={{
            textDecoration: "underline",
            mx: "0.75rem",
            fontWeight: "bold",
            fontSize: pxToRem([16, null, null, null, 18]),
          }}
          dangerouslySetInnerHTML={{
            __html: props.title,
          }}
        ></span>

        <span
          sx={{
            whiteSpace: "nowrap",
          }}
        >
          ({props.fileTypeFormatted} {props.fileSizeFormatted})
        </span>
      </a>
    )
  )
}

Download.propTypes = {
  title: PropTypes.string.isRequired,
  fileSizeFormatted: PropTypes.string.isRequired,
  fileTypeFormatted: PropTypes.string.isRequired,
  sourceRemote: PropTypes.string.isRequired,
  sourceLocal: PropTypes.shape({
    publicURL: PropTypes.string.isRequired,
  }),
}

const Downloads = (props) => {
  return (
    <Section
      className="section-bg"
      as="section"
      sx={{ variant: "section.smallColored", bg: "yellow", color: "black" }}
    >
      <Container>
        <Columns>
          <Column
            sx={{
              width: [null, null, null, "50%"],
            }}
          >
            {props.title && (
              <h2
                id={props.titleId}
                sx={{
                  variant: "text.sectionHeading",
                  mb: 0,
                }}
              >
                {props.title}
              </h2>
            )}
          </Column>
          <Column
            sx={{
              width: [null, null, null, "50%"],
            }}
          >
            {props.introduction && <Themed.p>{props.introduction}</Themed.p>}

            {props.downloads && props.downloads.length > 0 && (
              <ul>
                {props.downloads.map(({ download }, index) => {
                  return (
                    <li key={index} sx={{ mb: 3 }}>
                      <Download {...download} />
                    </li>
                  )
                })}
              </ul>
            )}
          </Column>
        </Columns>
      </Container>
    </Section>
  )
}

Downloads.propTypes = {
  title: PropTypes.string.isRequired,
  introduction: PropTypes.string,
  downloads: PropTypes.arrayOf(
    PropTypes.shape({
      download: PropTypes.shape(Download.propTypes),
    })
  ),
}

export default Downloads

/** @jsx jsx */
import { jsx, Themed } from "theme-ui"
import PropTypes from "prop-types"

import Section from "src/components/Section"
import Container from "src/components/Container"
import Columns from "src/components/Columns"
import Column from "src/components/Column"

import Link from "src/components/Link"

const KeyStat = (props) => {
  return (
    <article
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%",
        pr: [false, false, 24],
      }}
    >
      <div
        sx={{
          mb: [2, null, null, null, 3],
        }}
      >
        <h3
          sx={{
            fontSize: [46, false, false, false, false, 64],
            fontWeight: "normal",
            lineHeight: 1,
            letterSpacing: "-0.02em",
            mb: 3,
          }}
        >
          <span
            sx={{
              position: "relative",
              display: "inline-block",
              pb: 2,
              "&::before": {
                content: '""',
                position: "absolute",
                bottom: 0,
                left: 0,
                width: "100%",
                height: "4px",
                borderRadius: "2px",
                bg: `${props.themeColor.toLowerCase()}`,
              },
            }}
          >
            {props.title}
          </span>
        </h3>
        <Themed.p sx={{ mb: 0 }}>{props.body}</Themed.p>
      </div>

      {props.sourceUrl && props.sourceText ? (
        <div>
          <Link
            sx={{
              variant: "link.textSmall",
            }}
            to={props.sourceUrl}
          >
            {props.sourceText}
          </Link>
        </div>
      ) : props.sourceText ? (
        <small sx={{ color: "grey" }}>{props.sourceText}</small>
      ) : null}
    </article>
  )
}

KeyStat.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  sourceText: PropTypes.string,
  sourceUrl: PropTypes.string,
}

const KeyStats = (props) => {
  return (
    <Section
      as="section"
      sx={{
        variant: "section.large",
      }}
    >
      <Container>
        {props.sectionHeading && (
          <h2
            id={props.sectionHeadingId}
            sx={{
              variant: "text.sectionHeading",
            }}
          >
            {props.sectionHeading}
          </h2>
        )}

        <Columns
          sx={{
            mx: -2,
            my: -4,
          }}
        >
          {props.stats.map((stat, index) => (
            <Column
              key={index}
              sx={{
                width: ["100%", false, "50%", false, "25%"],
                px: 2,
                py: 4,
              }}
            >
              <KeyStat themeColor={props.themeColor} {...stat} />
            </Column>
          ))}
        </Columns>
      </Container>
    </Section>
  )
}

KeyStats.propTypes = {
  sectionHeading: PropTypes.string,
  themeColor: PropTypes.string,
  stats: PropTypes.arrayOf(PropTypes.shape(KeyStat.propTypes)),
}

KeyStats.defaultProps = {
  themeColor: "yellow",
}

export default KeyStats

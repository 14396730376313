/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"

import Section from "src/components/Section"
import Container from "src/components/Container"
import Columns from "src/components/Columns"
import Column from "src/components/Column"
import BannerWithImage from "src/components/BannerWithImage"
import BannerContentSingle from "src/components/BannerContentSingle"

const BannerFullWidthGroup = (props) => {
  const [first, second, third] = props.banners
  return (
    props.banners.length === 3 && (
      <Section sx={{ variant: "section.large", px: 2 }}>
        <Container>
          <BannerWithImage
            image={props.image}
            themeColor={first.themeColor}
            title={first.title}
            introduction={first.introduction}
            link={first.link}
          />
        </Container>
        <Container
          sx={{
            py: 2,
          }}
        >
          <Columns
            sx={{
              m: -1,
            }}
          >
            <Column
              sx={{
                p: 1,
                width: [null, null, "50%"],
              }}
            >
              <BannerContentSingle
                title={second.title}
                introduction={second.introduction}
                link={second.link}
                themeColor={second.themeColor}
              />
            </Column>
            <Column
              sx={{
                p: 1,
                width: [null, null, "50%"],
              }}
            >
              <BannerContentSingle
                title={third.title}
                introduction={third.introduction}
                link={third.link}
                themeColor={third.themeColor}
              />
            </Column>
          </Columns>
        </Container>
      </Section>
    )
  )
}

BannerFullWidthGroup.propTypes = {
  image: PropTypes.shape({
    sourceLocal: PropTypes.shape({
      childImageSharp: PropTypes.object.isRequired,
    }).isRequired,
  }).isRequired,
  banners: PropTypes.arrayOf(
    PropTypes.shape({
      themeColor: PropTypes.string,
      title: PropTypes.string.isRequired,
      introduction: PropTypes.string.isRequired,
      // link: PropTypes.string.isRequired,
      link: PropTypes.shape({
        title: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
      }),
    })
  ),
}

BannerFullWidthGroup.defaultProps = {
  themeColor: "yellow",
}

export default BannerFullWidthGroup

/** @jsx jsx */
import { jsx, Themed } from "theme-ui"
import PropTypes from "prop-types"

import { GatsbyImage, getImage } from "gatsby-plugin-image"

import SectionVertical from "src/components/SectionVertical"
import SectionNarrow from "src/components/SectionNarrow"
import Section from "src/components/Section"
import Container from "src/components/Container"
import ColumnsNarrow from "src/components/ColumnsNarrow"
import ColumnCardsNarrowThirds from "src/components/ColumnCardsNarrowThirds"
import Link from "src/components/Link"

const CaseStudyCard = (props) => {
  const gatsbyImageData = getImage(props?.image?.sourceLocal)
  return (
    props.link && (
      <Link
        to={props.link.url}
        sx={{
          variant: "cards.white",
          display: "flex",
          width: "100%",
          flexDirection: "column",
          justifyContent: "space-between",
          borderRadius: 8,
          textDecoration: "none",
        }}
      >
        {gatsbyImageData && (
          <div>
            <GatsbyImage
              image={gatsbyImageData}
              alt={props.image.altText ?? ""}
              style={{
                width: "100%",
                borderTopLeftRadius: 8,
                borderTopRightRadius: 8,
              }}
            />
          </div>
        )}
        <Themed.div
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "100%",
            // minHeight: [124, 124, 190],
            p: [3, 4],
          }}
        >
          <div>
            <Themed.h3 as="h1">{props.title}</Themed.h3>
            {props.introduction && <Themed.p>{props.introduction}</Themed.p>}
          </div>
          {props.description && <p>{props.description}</p>}
        </Themed.div>
      </Link>
    )
  )
}

CaseStudyCard.propTypes = {
  title: PropTypes.string.isRequired,
  introduction: PropTypes.string,
  description: PropTypes.string,
  link: PropTypes.shape({
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired,
}

const CaseStudies = (props) => {
  return (
    <SectionVertical as="section" sx={{ variant: "section.large" }}>
      <Section>
        <Container>
          {props.sectionHeading && (
            <Themed.h2 id={props.sectionHeadingId}>
              {props.sectionHeading}
            </Themed.h2>
          )}
        </Container>
      </Section>
      {props.caseStudies && props.caseStudies.length > 0 && (
        <SectionNarrow>
          <Container>
            <ColumnsNarrow>
              {props.caseStudies.map((caseStudy, index) => {
                return (
                  <ColumnCardsNarrowThirds key={index}>
                    <CaseStudyCard {...caseStudy} />
                  </ColumnCardsNarrowThirds>
                )
              })}
            </ColumnsNarrow>
          </Container>
        </SectionNarrow>
      )}
    </SectionVertical>
  )
}

CaseStudies.propTypes = {
  sectionHeading: PropTypes.string,
  caseStudies: PropTypes.arrayOf(PropTypes.shape(CaseStudyCard.propTypes)),
}

// CaseStudies.defaultProps = {
//   title: "Case studies",
//   cards: [{ theme: "red" }, { theme: "green" }, { theme: "yellow" }],
// }

export default CaseStudies

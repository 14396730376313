/** @jsx jsx */
import { jsx, Themed } from "theme-ui"
import PropTypes from "prop-types"
import { useInView } from "react-intersection-observer"

import Link from "../Link"

const KeyStatList = (props) => {
  const { ref, inView, entry } = useInView({
    threshold: 0.55,
  })

  return (
    <article
      ref={ref}
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        pr: [false, false, 24],
      }}
    >
      <h3
        sx={{
          display: "block",
          fontSize: [18, false, false, false, false, 24],
          fontWeight: "700",
          lineHeight: 1.11,
          letterSpacing: "0.01em",
          mb: 6,
          mt: 3,
        }}
      >
        <span
          sx={{
            position: "relative",
            display: "inline-block",
            pb: 3,
            "&::before": {
              display: "block",
              content: "''",
              position: "absolute",
              bottom: "-4px",
              left: 0,
              width: `${inView ? "120px" : "0px"}`,
              height: "4px",
              borderRadius: "2px",
              bg: `${props.themeColor.toLowerCase()}`,
              transition: "width 1s ease-out",
            },
          }}
        >
          {props.heading}
        </span>
      </h3>

      <div sx={{ mb: "12px" }}>
        {props.stats.map((stat, index) => (
          <KeyStat key={index} {...stat} />
        ))}
      </div>

      {props.sourceUrl && props.sourceText ? (
        <div sx={{ mt: "auto" }}>
          <Link
            sx={{
              variant: "link.textSmall",
            }}
            to={props.sourceUrl}
          >
            {props.sourceText}
          </Link>
        </div>
      ) : props.sourceText ? (
        <small
          sx={{
            color: "grey",
            mt: "auto",
          }}
        >
          {props.sourceText}
        </small>
      ) : null}
    </article>
  )
}

KeyStatList.propTypes = {
  themeColor: PropTypes.oneOf(["Red", "Blue", "Green", "Yellow"]),
  heading: PropTypes.string.isRequired,
  stats: PropTypes.array.isRequired,
  sourceText: PropTypes.string,
  sourceUrl: PropTypes.string,
}

const KeyStat = (props) => {
  return (
    <div
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "baseline",
        mb: 4,
      }}
    >
      <h4
        sx={{
          fontSize: ["18px", false, false, false, false, "22px"],
          fontWeight: "normal",
          lineHeight: 1,
          letterSpacing: "-0.02em",
          mb: 0,
          mt: 0,
          mr: 4,
          minWidth: "4ch",
        }}
      >
        {props.title}
      </h4>
      <Themed.p
        sx={{
          fontSize: 2,
          mt: 0,
          mb: 0,
        }}
      >
        {props.body}
      </Themed.p>
    </div>
  )
}

KeyStat.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
}

export default KeyStatList

/** @jsx jsx */
import { jsx, Styled } from "theme-ui"
import PropTypes from "prop-types"

import Section from "src/components/Section"
import Container from "src/components/Container"
import Columns from "src/components/Columns"
import Column from "src/components/Column"

import KeyStatFeature from "src/components/KeyStatFeature"

const KeyStatFeatureBlock = (props) => {
  return (
    <Section
      as="section"
      sx={{
        variant: "section.large",
      }}
    >
      <Container>
        {props.sectionHeading && (
          <h2
            id={props.sectionHeadingId}
            sx={{
              variant: "text.sectionHeading",
            }}
          >
            {props.sectionHeading}
          </h2>
        )}

        <Columns
          sx={{
            mx: -2,
            my: -4,
          }}
        >
          {props.stats.map((stat, index) => (
            <Column
              key={index}
              sx={{
                flex: "1 1 50%",
                width: ["100%", false, "50%", false, "25%"],
                px: 2,
                py: 4,
              }}
            >
              <KeyStatFeature
                themeColor={props.themeColor}
                sourceText={props.sourceText}
                sourceUrl={props.sourceUrl}
                {...stat}
              />
            </Column>
          ))}
        </Columns>
      </Container>
    </Section>
  )
}

KeyStatFeatureBlock.propTypes = {
  sectionHeading: PropTypes.string,
  themeColor: PropTypes.string,
  stats: PropTypes.arrayOf(PropTypes.shape(KeyStatFeature.propTypes)),
}

KeyStatFeatureBlock.defaultProps = {
  themeColor: "yellow",
}

export default KeyStatFeatureBlock

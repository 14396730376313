import React from "react"
import PropTypes from "prop-types"

import GetHelpBlock from "src/components/GetHelpBlock"

function GlobalModuleRenderer({ globalModule }) {
  switch (globalModule) {
    case "get_help":
      return <GetHelpBlock />
    default:
      return null
  }
}

GlobalModuleRenderer.propTypes = {
  globalModule: PropTypes.string.isRequired,
}

export default GlobalModuleRenderer

/** @jsx jsx */
import { jsx, Styled } from "theme-ui"
import PropTypes from "prop-types"

import Section from "src/components/Section"
import Container from "src/components/Container"
import Columns from "src/components/Columns"
import Column from "src/components/Column"

import KeyStatList from "src/components/KeyStatList"

const KeyStatListBlock = (props) => {
  return (
    <Section
      as="section"
      sx={{
        variant: "section.large",
      }}
    >
      <Container>
        {props.sectionHeading && (
          <h2
            id={props.sectionHeadingId}
            sx={{
              variant: "text.sectionHeading",
            }}
          >
            {props.sectionHeading}
          </h2>
        )}

        <Columns
          sx={{
            flexWrap: ["wrap", false, false, false, false, "nowrap"],
            mx: -2,
            my: -4,
          }}
        >
          {props.list.map((subList, index) => (
            <Column
              key={index}
              sx={{
                flexBasis: ["100%", false, false, false, "50%", "100%"],
                pl: 2,
                pr: [2, false, false, false, false, 4],
                pt: 2,
                pb: ["40px", false, false, false, 5, 4],
                mr: ["0", false, false, false, false, "36px"],
                mb: 0,
                borderRight: [
                  "none",
                  false,
                  false,
                  false,
                  false,
                  "2px solid rgba(235, 235, 235, 0.5)",
                ],
                borderBottom: [
                  "2px solid rgba(235, 235, 235, 0.5)",
                  false,
                  false,
                  false,
                  "none",
                  false,
                ],
                "&:last-child": {
                  pr: 2,
                  borderRight: "none",
                },
              }}
            >
              <KeyStatList
                themeColor={props.themeColor}
                sourceText={props.sourceText}
                sourceUrl={props.sourceUrl}
                {...subList}
              />
            </Column>
          ))}
        </Columns>
      </Container>
    </Section>
  )
}

KeyStatListBlock.propTypes = {
  sectionHeading: PropTypes.string,
  themeColor: PropTypes.string,
  stats: PropTypes.arrayOf(PropTypes.shape(KeyStatList.propTypes)),
}

KeyStatListBlock.defaultProps = {
  themeColor: "yellow",
}

export default KeyStatListBlock

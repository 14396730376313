/** @jsx jsx */
import { jsx, Themed } from "theme-ui"
import PropTypes from "prop-types"
import { useInView } from "react-intersection-observer"

import Increase from "./icons/Increase.js"
import Decrease from "./icons/Decrease.js"
import Comparison from "./icons/Comparison.js"

import Link from "../Link"

const KeyStatFeature = (props) => {
  const { ref, inView, entry } = useInView({
    threshold: 0.55,
  })

  return (
    <article
      ref={ref}
      sx={{
        display: "grid",
        gridTemplateAreas: [
          "'title' 'body' 'source'",
          false,
          false,
          false,
          `${
            props.size === "standard"
              ? "'title' 'body' 'source'"
              : "'title body' '. source'"
          }`,
          false,
        ],
        columnGap: "50px",
        rowGap: 5,
        justifyContent: "start",
        alignItems: "center",
        height: "100%",
        pr: [false, false, 24],
      }}
    >
      <h3
        sx={{
          gridArea: "title",
          display: "flex",
          fontSize: ["12.5vw", false, 68, false, false, 100],
          fontWeight: "normal",
          lineHeight: 1,
          letterSpacing: "-0.02em",
          mb: 3,
          mt: 3,
        }}
      >
        <span
          sx={{
            position: "relative",
            display: "inline-block",
            width: "max-content",
            pb: [
              3,
              false,
              false,
              false,
              `${props.size === "standard" ? 3 : ""}`,
              false,
            ],
            "&::before": {
              display: [
                "inherit",
                false,
                false,
                false,
                `${props.size === "standard" ? "inherit" : "none"}`,
                false,
              ],
              content: '""',
              position: "absolute",
              bottom: "-4px",
              left: 0,
              width: `${inView ? "100%" : "0%"}`,
              height: "4px",
              borderRadius: "2px",
              bg: `${props.themeColor.toLowerCase()}`,
              transition: "width 1s ease-out",
            },
          }}
        >
          {props.title}
        </span>
        <div
          sx={{
            position: "relative",
            display: "inline-flex",
            "&::after": {
              display: [
                "none",
                false,
                false,
                false,
                `${props.size === "standard" ? "none" : "inherit"}`,
                false,
              ],
              content: '""',
              position: "absolute",
              bottom: 0,
              right: "-4px",
              width: "4px",
              height: `${inView ? "100%" : "0%"}`,
              borderRadius: "2px",
              bg: `${props.themeColor.toLowerCase()}`,
              transition: "height 1s ease-out",
            },
          }}
        >
          <div
            sx={{
              display: "inline-flex",
              ml: "28px",
              mr: 6,
            }}
            aria-hidden="true"
          >
            {props.type === "increase" ? (
              <Increase />
            ) : props.type === "decrease" ? (
              <Decrease />
            ) : (
              <Comparison />
            )}
          </div>
        </div>
      </h3>
      <Themed.p
        sx={{
          gridArea: "body",
          fontSize: "22px",
          mb: 0,
          mt: 0,
        }}
      >
        {props.body}
      </Themed.p>

      {props.sourceUrl && props.sourceText ? (
        <div
          sx={{
            gridArea: "source",
          }}
        >
          <Link
            sx={{
              variant: "link.textSmall",
            }}
            to={props.sourceUrl}
          >
            {props.sourceText}
          </Link>
        </div>
      ) : props.sourceText ? (
        <small
          sx={{
            gridArea: "source",
            color: "grey",
          }}
        >
          {props.sourceText}
        </small>
      ) : null}
    </article>
  )
}

KeyStatFeature.propTypes = {
  themeColor: PropTypes.oneOf(["Red", "Blue", "Green", "Yellow"]),
  size: PropTypes.oneOf(["standard", "full-width"]).isRequired,
  type: PropTypes.oneOf(["increase", "decrease", "comparison"]).isRequired,
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  sourceText: PropTypes.string,
  sourceUrl: PropTypes.string,
}

export default KeyStatFeature

/** @jsx jsx */
import { jsx, Themed } from "theme-ui"
import PropTypes from "prop-types"

import Section from "src/components/Section"
import SectionNarrow from "src/components/SectionNarrow"
import SectionVertical from "src/components/SectionVertical"

import Container from "src/components/Container"
import BannerWithImage from "src/components/BannerWithImage"

const BannerFullWidth = (props) => {
  return (
    <SectionVertical as="section" sx={{ variant: "section.large" }}>
      <Section>
        <Container>
          {props.sectionHeading && (
            <Themed.h2 id={props.sectionHeadingId}>
              {props.sectionHeading}
            </Themed.h2>
          )}
        </Container>
      </Section>
      <SectionNarrow>
        <Container>
          <BannerWithImage {...props} />
        </Container>
      </SectionNarrow>
    </SectionVertical>
  )
}

BannerFullWidth.propTypes = {
  sectionHeading: PropTypes.string.isRequired,
  themeColor: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  introduction: PropTypes.string.isRequired,
  // link: PropTypes.string.isRequired,
  link: PropTypes.shape({
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }),

  image: PropTypes.shape({
    sourceLocal: PropTypes.shape({
      childImageSharp: PropTypes.object.isRequired,
    }).isRequired,
  }).isRequired,
}

export default BannerFullWidth

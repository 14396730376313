/** @jsx jsx */
import { jsx } from "theme-ui"

const ColumnCardsNarrowQuarter = (props) => {
  const { as: TagName = "div", ...rest } = props
  return (
    <TagName
      {...rest}
      sx={{
        width: ["50%", null, "50%", null, "25%"],
        display: "flex",
        p: 1,
      }}
    />
  )
}

export default ColumnCardsNarrowQuarter

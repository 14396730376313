import React from "react"

import BoldStatement from "src/slices/BoldStatement"
import KeyStats from "src/slices/KeyStats"
import KeyStatListBlock from "./KeyStatListBlock"
import KeyStatFeatureBlock from "./KeyStatFeatureBlock"
import BannerFullWidth from "src/slices/BannerFullWidth"
import BannerFullWidthGroup from "src/slices/BannerFullWidthGroup"
import RelatedResources from "src/slices/RelatedResources"
import CaseStudies from "src/slices/CaseStudies"
import GlobalModuleRenderer from "src/slices/GlobalModuleRenderer"
import Downloads from "src/slices/Downloads"
import slugify from "slugify"

function RichContentRenderer({ acfRichContent }) {
  const { richContent } = acfRichContent
  return (
    richContent.length > 0 &&
    richContent.map((slice, index) => {
      switch (slice && slice.__typename) {
        case "WPGraphQL_Page_Acfrichcontent_RichContent_BoldStatement":
        case "WPGraphQL_Article_Acfrichcontent_RichContent_BoldStatement":
        case "WPGraphQL_TaxHub_Acfrichcontent_RichContent_BoldStatement":
        case "WPGraphQL_Resource_Acfrichcontent_RichContent_BoldStatement":
        case "WPGraphQL_NewsAndEvent_Acfrichcontent_RichContent_BoldStatement":
        case "WPGraphQL_LegalIntervention_Acfrichcontent_RichContent_BoldStatement":
        case "WPGraphQL_PolicySubmission_Acfrichcontent_RichContent_BoldStatement":
          return (
            <BoldStatement
              key={index}
              sectionHeading={slice.sectionHeading}
              sectionHeadingId={(slice.sectionHeading || slice.statement) ? slugify(
                slice.sectionHeading || slice.statement
              ) : undefined}
              statement={slice.statement}
              link={slice.link}
            />
          )

        case "WPGraphQL_Page_Acfrichcontent_RichContent_KeyStats":
        case "WPGraphQL_Article_Acfrichcontent_RichContent_KeyStats":
        case "WPGraphQL_TaxHub_Acfrichcontent_RichContent_KeyStats":
        case "WPGraphQL_Resource_Acfrichcontent_RichContent_KeyStats":
        case "WPGraphQL_NewsAndEvent_Acfrichcontent_RichContent_KeyStats":
        case "WPGraphQL_LegalIntervention_Acfrichcontent_RichContent_KeyStats":
        case "WPGraphQL_PolicySubmission_Acfrichcontent_RichContent_KeyStats":
        case "WPGraphQL_Infographic_Acfrichcontent_RichContent_KeyStats":
          return (
            <KeyStats
              key={index}
              sectionHeading={slice.sectionHeading}
              sectionHeadingId={slice.sectionHeading? slugify(slice.sectionHeading) : undefined}
              themeColor={slice.themeColor}
              stats={slice.stats}
            />
          )

        case "WPGraphQL_Page_Acfrichcontent_RichContent_KeyStatsFeature":
        case "WPGraphQL_Article_Acfrichcontent_RichContent_KeyStatsFeature":
        case "WPGraphQL_TaxHub_Acfrichcontent_RichContent_KeyStatsFeature":
        case "WPGraphQL_Resource_Acfrichcontent_RichContent_KeyStatsFeature":
        case "WPGraphQL_NewsAndEvent_Acfrichcontent_RichContent_KeyStatsFeature":
        case "WPGraphQL_LegalIntervention_Acfrichcontent_RichContent_KeyStatsFeature":
        case "WPGraphQL_PolicySubmission_Acfrichcontent_RichContent_KeyStatsFeature":
        case "WPGraphQL_Infographic_Acfrichcontent_RichContent_KeyStatsFeature":
          return (
            <KeyStatFeatureBlock
              key={index}
              sectionHeading={slice.sectionHeading}
              sectionHeadingId={slice.sectionHeading? slugify(slice.sectionHeading) : undefined}
              themeColor={slice.themeColor}
              stats={slice.stats}
              sourceText={slice.sourceText}
              sourceUrl={slice.sourceUrl}
            />
          )

        case "WPGraphQL_Page_Acfrichcontent_RichContent_KeyStatsList":
        case "WPGraphQL_Article_Acfrichcontent_RichContent_KeyStatsList":
        case "WPGraphQL_TaxHub_Acfrichcontent_RichContent_KeyStatsList":
        case "WPGraphQL_Resource_Acfrichcontent_RichContent_KeyStatsList":
        case "WPGraphQL_NewsAndEvent_Acfrichcontent_RichContent_KeyStatsList":
        case "WPGraphQL_LegalIntervention_Acfrichcontent_RichContent_KeyStatsList":
        case "WPGraphQL_PolicySubmission_Acfrichcontent_RichContent_KeyStatsList":
        case "WPGraphQL_Infographic_Acfrichcontent_RichContent_KeyStatsList":
          return (
            <KeyStatListBlock
              key={index}
              sectionHeading={slice.sectionHeading}
              sectionHeadingId={slice.sectionHeading? slugify(slice.sectionHeading) : undefined}
              themeColor={slice.themeColor}
              list={slice.list}
              sourceText={slice.sourceText}
              sourceUrl={slice.sourceUrl}
            />
          )

        case "WPGraphQL_Page_Acfrichcontent_RichContent_BannerFullWidth":
        case "WPGraphQL_Article_Acfrichcontent_RichContent_BannerFullWidth":
        case "WPGraphQL_TaxHub_Acfrichcontent_RichContent_BannerFullWidth":
        case "WPGraphQL_Resource_Acfrichcontent_RichContent_BannerFullWidth":
        case "WPGraphQL_NewsAndEvent_Acfrichcontent_RichContent_BannerFullWidth":
        case "WPGraphQL_LegalIntervention_Acfrichcontent_RichContent_BannerFullWidth":
        case "WPGraphQL_PolicySubmission_Acfrichcontent_RichContent_BannerFullWidth":
          return (
            <BannerFullWidth
              key={index}
              sectionHeading={slice.sectionHeading}
              sectionHeadingId={slice.sectionHeading? slugify(slice.sectionHeading) : undefined}
              themeColor={slice.themeColor}
              title={slice.title}
              introduction={slice.introduction}
              image={slice.image}
              link={slice.link}
            />
          )

        case "WPGraphQL_Page_Acfrichcontent_RichContent_BannerFullWidthGroup":
        case "WPGraphQL_Article_Acfrichcontent_RichContent_BannerFullWidthGroup":
        case "WPGraphQL_TaxHub_Acfrichcontent_RichContent_BannerFullWidthGroup":
        case "WPGraphQL_Resource_Acfrichcontent_RichContent_BannerFullWidthGroup":
        case "WPGraphQL_NewsAndEvent_Acfrichcontent_RichContent_BannerFullWidthGroup":
        case "WPGraphQL_LegalIntervention_Acfrichcontent_RichContent_BannerFullWidthGroup":
        case "WPGraphQL_PolicySubmission_Acfrichcontent_RichContent_BannerFullWidthGroup":
          return (
            <BannerFullWidthGroup
              key={index}
              image={slice.image}
              banners={slice.banners}
            />
          )

        case "WPGraphQL_Page_Acfrichcontent_RichContent_RelatedResources":
        case "WPGraphQL_Article_Acfrichcontent_RichContent_RelatedResources":
        case "WPGraphQL_TaxHub_Acfrichcontent_RichContent_RelatedResources":
        case "WPGraphQL_Resource_Acfrichcontent_RichContent_RelatedResources":
        case "WPGraphQL_NewsAndEvent_Acfrichcontent_RichContent_RelatedResources":
        case "WPGraphQL_LegalIntervention_Acfrichcontent_RichContent_RelatedResources":
        case "WPGraphQL_PolicySubmission_Acfrichcontent_RichContent_RelatedResources":
          return (
            <RelatedResources
              key={index}
              {...slice}
              sectionHeadingId={slice.sectionHeading? slugify(slice.sectionHeading) : undefined}
            />
          )

        case "WPGraphQL_Page_Acfrichcontent_RichContent_RelatedCaseStudies":
        case "WPGraphQL_Article_Acfrichcontent_RichContent_RelatedCaseStudies":
        case "WPGraphQL_TaxHub_Acfrichcontent_RichContent_RelatedCaseStudies":
        case "WPGraphQL_Resource_Acfrichcontent_RichContent_RelatedCaseStudies":
        case "WPGraphQL_NewsAndEvent_Acfrichcontent_RichContent_RelatedCaseStudies":
        case "WPGraphQL_LegalIntervention_Acfrichcontent_RichContent_RelatedCaseStudies":
        case "WPGraphQL_PolicySubmission_Acfrichcontent_RichContent_RelatedCaseStudies":
          return (
            <CaseStudies
              key={index}
              {...slice}
              sectionHeadingId={slice.sectionHeading? slugify(slice.sectionHeading) : undefined}
            />
          )

        case "WPGraphQL_Page_Acfrichcontent_RichContent_Downloads":
        case "WPGraphQL_Article_Acfrichcontent_RichContent_Downloads":
        case "WPGraphQL_TaxHub_Acfrichcontent_RichContent_Downloads":
        case "WPGraphQL_Resource_Acfrichcontent_RichContent_Downloads":
        case "WPGraphQL_NewsAndEvent_Acfrichcontent_RichContent_Downloads":
        case "WPGraphQL_LegalIntervention_Acfrichcontent_RichContent_Downloads":
        case "WPGraphQL_PolicySubmission_Acfrichcontent_RichContent_Downloads":
          return (
            <Downloads
              key={index}
              {...slice}
              titleId={slice.title? slugify(slice.title) : undefined}
            />
          )

        case "WPGraphQL_Page_Acfrichcontent_RichContent_GlobalModule":
        case "WPGraphQL_Article_Acfrichcontent_RichContent_GlobalModule":
        case "WPGraphQL_TaxHub_Acfrichcontent_RichContent_GlobalModule":
        case "WPGraphQL_Resource_Acfrichcontent_RichContent_GlobalModule":
        case "WPGraphQL_NewsAndEvent_Acfrichcontent_RichContent_GlobalModule":
        case "WPGraphQL_LegalIntervention_Acfrichcontent_RichContent_GlobalModule":
        case "WPGraphQL_PolicySubmission_Acfrichcontent_RichContent_GlobalModule":
          return (
            <GlobalModuleRenderer
              key={index}
              globalModule={slice.globalModule}
            />
          )

        // case "case_studies":
        //   return (
        //     <CaseStudies
        //       key={index}
        //       primary={slice.primary}
        //       fields={slice.fields}
        //     />
        //   )

        default:
          return false
      }
    })
  )
}

RichContentRenderer.propTypes = {}

export default RichContentRenderer

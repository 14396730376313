/** @jsx jsx */
import { jsx } from "theme-ui"

const ColumnCardsNarrowThirds = (props) => {
  const { as: TagName = "div", ...rest } = props
  return (
    <TagName
      {...rest}
      sx={{
        width: ["100%", null, "50%", null, "33.3333%"],
        display: "flex",
        p: 1,
      }}
    />
  )
}

export default ColumnCardsNarrowThirds

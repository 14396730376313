/** @jsx jsx */
import { jsx } from "theme-ui"
import css from "@emotion/css"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { pxToRem } from "src/helpers"
import { bgThemes } from "src/theme"
import PropTypes from "prop-types"

import Section from "src/components/Section"
import Container from "src/components/Container"
import Columns from "src/components/Columns"
import Column from "src/components/Column"
import Breadcrumb from "src/components/Breadcrumb"
import {
  HeroWrap,
  HeroTitle,
  HeroIntroduction,
} from "src/components/HeroComponents"

const HeroWithImageRight = (props) => {
  const {
    title,
    introduction,
    image,
    breadcrumbs,
    bgColor,
    isRTL,
    buttonLink,
    buttonColour,
  } = props
  const gatsbyImageData = getImage(image?.sourceLocal)
  return (
    <div
      sx={{
        variant: "spacing.headerPadding",
        ...bgThemes[bgColor ? bgColor : "white"],
        position: "relative",
        "&:after": {
          content: "''",
          position: "absolute",
          bottom: 0,
          left: 0,
          width: "100%",
          height: "4px",
          variant: "gradient.default",
        },
      }}
    >
      <Section
        className="hero-container"
        sx={{
          variant: "spacing.heroImageRight",
          maxWidth: pxToRem(1440),
          position: "relative",
          mx: "auto",
          overflow: "hidden",
          "& *:focus-visible": bgColor === "yellow" && {
            outlineColor: "white !important",
          },
        }}
      >
        <Container>
          <Columns>
            <Column
              sx={{
                width: ["100%", null, null, null, "50%"],
              }}
            >
              <div
                sx={{
                  pt: [7],
                  pb: [6, 6, 7],
                  // py: [5, 5, 6, 7],
                  maxWidth: pxToRem([500, null, null, 600]),
                }}
              >
                <Breadcrumb isRightAligned={isRTL} breadcrumbs={breadcrumbs} />
                <HeroWrap
                  isRTL={isRTL}
                  buttonLink={buttonLink}
                  buttonColour={buttonColour}
                  bgColor={bgColor || "white"}
                >
                  <HeroTitle>{title}</HeroTitle>
                  <HeroIntroduction>{introduction}</HeroIntroduction>
                </HeroWrap>
              </div>
            </Column>
            <Column
              sx={{
                width: ["100%", null, null, null, "41.6666%"],
                ml: [null, null, null, null, "8.33333%"],
                position: [null, null, null, null, "absolute"],
                right: 0,
                top: 0,
                bottom: 0,
              }}
            >
              <figure>
                {gatsbyImageData && (
                  <GatsbyImage
                    sx={{
                      display: "block",
                      marginLeft: "-20px",
                      marginRight: "-20px",
                      maxWidth: "440px",
                      "@media (min-width: 1000px)": {
                        maxWidth: "none",
                        position: "absolute !important",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                      },
                    }}
                    objectFit="cover"
                    objectPosition="50% 50%"
                    image={gatsbyImageData}
                    alt={image.altText ?? ""}
                  />
                )}
                {image && image.caption && (
                  <figcaption
                    dangerouslySetInnerHTML={{ __html: image.caption }}
                    sx={{
                      padding: "12px",
                      backgroundColor: "rgba(255, 255, 255, 0.6)",
                      fontStyle: "italic",
                      marginLeft: "-20px",
                      color: "initial",
                      "@media (min-width: 1000px)": {
                        position: "absolute",
                        bottom: 0,
                        right: 0,
                      },
                    }}
                  />
                )}
              </figure>
            </Column>
          </Columns>
        </Container>
      </Section>
    </div>
  )
}

HeroWithImageRight.propTypes = {
  title: PropTypes.string.isRequired,
  isRTL: PropTypes.bool,
  introduction: PropTypes.string,
}

HeroWithImageRight.defaultProps = {
  isRTL: false,
}

export default HeroWithImageRight
